import React from 'react'
import { ReactComponent as FBLogo } from '../svg/fb.svg'
import { ReactComponent as InstaLogo } from '../svg/instagram.svg'
import { ReactComponent as YouTubeLogo } from '../svg/youtube0.svg'
import { ReactComponent as TGLogo } from '../svg/telegram.svg'
// import { ReactComponent as ItunesLogo } from '../svg/apple-music.svg'
// import { ReactComponent as SpotifyLogo } from '../svg/spotify.svg'
// import { ReactComponent as VKLogo } from '../svg/vk.svg'


const SocialLinks = () => {
  return (
    <div className='social-links'>
      <a key='instagram'
         href='https://www.instagram.com/dropbled/'
         target='_blank'
         title='Instagram'
         rel='noopener noreferrer'>
        <InstaLogo title='Instagram' />
      </a>

      <a key='youtube'
         href='https://www.youtube.com/@poshlayamolly'
         target='_blank'
         title='YouTube'
         rel='noopener noreferrer'>
        <YouTubeLogo title='YouTube' />
      </a>

      <a key='facebook'
         href='https://www.facebook.com/poshlayamollyofficial'
         target='_blank'
         title='Facebook'
         rel='noopener noreferrer'>
        <FBLogo title='Facebook' />
      </a>

      <a key='telegram'
         href='https://t.me/poshlayamollyofficial'
         target='_blank'
         title='Telegram'
         rel='noopener noreferrer'>
        <TGLogo title='Telegram' />
      </a>

      {/*<a key='vk'
        href='https://vk.com/poshlaya_molly'
        target='_blank'
        title='VK'
        rel='noopener noreferrer'>
       <VKLogo title='VK' />
      </a>*/}

      {/*<a key='spotify'
         href='https://open.spotify.com/artist/0xByDfltDVpk6LDsUMHyI2?si=wwYdgNrETbORPIAZ17rN8g'
         target='_blank'
         title='Spotify'
         rel='noopener noreferrer'>
        <SpotifyLogo title='Spotify' />
      </a>
      <a key='apple music'
         href='https://music.apple.com/ru/artist/%D0%BF%D0%BE%D1%88%D0%BB%D0%B0%D1%8F-%D0%BC%D0%BE%D0%BB%D0%BB%D0%B8/1212756868'
         target='_blank'
         title='Apple Music'
         rel='noopener noreferrer'>
        <ItunesLogo title='Apple Music' />
      </a>*/}

    </div>
  )
}

export default SocialLinks
