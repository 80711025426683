import { initializeApp } from 'firebase/app'

export const appName = 'poshlayamolly-832ed'

const config = {
  apiKey: 'AIzaSyBg649xdYtcEZFak338cZT1kq9Gkc4CTjY',
  authDomain: `${appName}.firebaseapp.com`,
  databaseURL: `https://${appName}.firebaseio.com`,
  projectId: appName,
  storageBucket: `${appName}.appspot.com`,
  messagingSenderId: '46371325056'
}

initializeApp(config)
