import React, { useState } from 'react'
import Header from './components/Header'
import Main from './components/Main'

import MainPassed from './components/MainPassed'
import MainPassedTwo from './components/MainPassedTwo'
import MainPassedSaveUkraine from './components/MainPassedSaveUkraine'

// import MainNew from './components/MainNew'
import Shows from './components/Shows'
// import ShowsNew from './components/ShowsNew'
import Merch from './components/Merch'
import Footer from './components/Footer'
import './style.js'
import { shows } from './data/shows'
import { showsNext } from './data/showsNext'

import { pastShows2023 } from './data/pastShows2023'
import { pastShows2022 } from './data/pastShows2022'
import { pastShowsSaveUkraine2022 } from './data/pastShowsSaveUkraine2022'


// import { YMInitializer } from 'react-yandex-metrika';

function App() {
  const [list23Visible, setList23Visible] = useState(false)
  const [list22Visible, setList22Visible] = useState(false)
  const [list22SaveUkraineVisible, setList22SaveUkraineVisible] = useState(false)

  function handle23Click() {
    setList23Visible(!list23Visible)

    setList22Visible(false)
    setList22SaveUkraineVisible(false)
  }

  function handle22Click() {
    setList22Visible(!list22Visible)

    setList22SaveUkraineVisible(false)
    setList23Visible(false)
  }

  function handle22SaveUkraineClick() {
    setList22SaveUkraineVisible(!list22SaveUkraineVisible)

    setList23Visible(false)
    setList22Visible(false)
  }

  // <main className='main'>
  //   <Main year='2023' />
  //   <Shows shows={shows} />
  //   <Main year='2024' />
  //   <Shows shows={showsNext} />
  //
  //   <MainPassed handler={handle23Click} />
  //   { list23Visible ? <Shows shows={pastShows2023} /> : null }
  //
  //   <MainPassedTwo handler={handle22Click} />
  //   { list22Visible ? <Shows shows={pastShows2022} /> : null }
  //
  //   <MainPassedSaveUkraine handler={handle22SaveUkraineClick} />
  //   {
  //     list22SaveUkraineVisible ?
  //     <div className='save'>
  //       <p>
  //         Ми направили 1 084 603,62 гривень (29 680,37 €) на допомогу захисникам України з благодійних концертів у Європі «SAVE UKRAINE TOUR»:
  //       </p>
  //       <p>
  //         — за 932206,65 гривень ми купили та доставили з Англії броньовану фуру Mercedes-Benz, яка вже перебуває на передовій у 72-ої бригади ЗСУ України;
  //       </p>
  //       <p>
  //         — на 152396,97 гривень ми підтримали фонд «За Луганщину», фонд Міністерства культури України, та закрили збір на дрон Mavic 3 для потреб Харківської області.
  //       </p>
  //       <img src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/2023%2Fsave%2FIMG_4742.JPG?alt=media&token=9a6483b5-b9a5-4df0-b4a0-6eb8815939aa' width='100' alt='SAVE UKRAINE TOUR' />
  //       <img src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/2023%2Fsave%2FIMG_4740.JPG?alt=media&token=4586f979-1489-46c7-ab89-1a7201adf5d2' width='100' alt='SAVE UKRAINE TOUR' />
  //     </div> : null
  //   }
  //   { list22SaveUkraineVisible ? <Shows shows={pastShowsSaveUkraine2022} /> : null }
  //
  //
  //   {/*<div className='help'>
  //     <a href='mailto:booking@dropbled.com'>booking@dropbled.com</a>
  //   </div>*/}
  //   {/*<Merch />/*/}
  // </main>

  return (
    <div className='app'>
      <Header />

      {/*MAIN WAS HERE*/}

      <Footer />

      {/*<div className='bg'>
        <div className='bg__kiss'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/kiss-1.png?alt=media&token=be713d37-d2df-44a1-86f3-2427fa515343'
            width='30'
            alt='*'
          />
        </div>
        <div className='bg__kiss'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/kiss-2.png?alt=media&token=cda5f899-8683-48e1-ade8-4d399f17f9c2'
            width='30'
            alt='*'
          />
        </div>
        <div className='bg__kiss'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/kiss-3.png?alt=media&token=5f713150-4a22-4d4d-8854-962898ce523f'
            width='30'
            alt='*'
          />
        </div>
        <div className='bg__kiss'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/kiss-1.png?alt=media&token=be713d37-d2df-44a1-86f3-2427fa515343'
            width='30'
            alt='*'
          />
        </div>
        <div className='bg__kiss'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/kiss-2.png?alt=media&token=cda5f899-8683-48e1-ade8-4d399f17f9c2'
            width='30'
            alt='*'
          />
        </div>
        <div className='bg__kiss'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/kiss-3.png?alt=media&token=5f713150-4a22-4d4d-8854-962898ce523f'
            width='30'
            alt='*'
          />
        </div>
        <div className='bg__kiss'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/kiss-1.png?alt=media&token=be713d37-d2df-44a1-86f3-2427fa515343'
            width='30'
            alt='*'
          />
        </div>
        <div className='bg__kiss'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/kiss-2.png?alt=media&token=cda5f899-8683-48e1-ade8-4d399f17f9c2'
            width='30'
            alt='*'
          />
        </div>
        <div className='bg__kiss'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/kiss-3.png?alt=media&token=5f713150-4a22-4d4d-8854-962898ce523f'
            width='30'
            alt='*'
          />
        </div>
      </div>*/}
    </div>
)}

export default App
