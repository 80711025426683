import React from 'react'
import ScrollDownIcon from './ScrollDownIcon'
import { ReactComponent as PMLogo } from '../svg/pm-rat-logo.svg'

const Header = () => {
  return (
    <header className='header'>
      <div className='header__logo-wrapper'>
        <PMLogo className='header__logo' title='ПОШЛАЯ МОЛЛИ' />
      </div>
      {/*<ScrollDownIcon />*/}
    </header>
  )
}

export default Header
