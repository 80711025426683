import React, { Component } from 'react'
import SocialLinks from './SocialLinks'

class Footer extends Component {
  timeout = null

  state = {
    isScrolling: false,
    pageOffset: 0
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    this.unlisten()
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    this.setState({ isScrolling: true })

    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false, pageOffset: window.pageYOffset })
    }, 100)
  }

  render() {
    const { pageOffset } = this.state
    const footerClass = pageOffset > 300 ? 'footer' : 'footer footer--hidden'

    return (
      <footer className={footerClass}>
        <div className='footer__inner'>
          <div className='footer__merch'>
            <a href='https://theindigostuff.com/poshlaya-molly' target='_blank' title='ОНЛАЙН-МАГАЗИН' rel='noopener noreferrer'>
              МЕРЧ
            </a>
          </div>
          <div className='footer__social'>
            <SocialLinks />
          </div>
          <div className='footer__copyright'>
            <a href='https://indigoflow.space' target='_blank' title='Сайт разработан студией INDIGO FLOW' rel='noopener noreferrer'>
              2023
            </a>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
