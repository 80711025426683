import React, { Component } from 'react'
import { ReactComponent as ArrowDown } from '../svg/angle-arrow-down.svg'
import { debounce, getVH } from '../utils'

class ScrollDownIcon extends Component {
  state = {
    scrollFromTop: 0
  }

  componentDidMount() {
    const self = this

    const myEfficientFn = debounce(function() {
      self.setState({
        scrollFromTop: getVH()
      })
    }, 250)

    window.addEventListener('scroll', myEfficientFn)

    // document.addEventListener('mousedown', onMouseAction)
    // document.addEventListener('mouseup', onMouseAction)
    //
    // function onMouseAction() {
    //
    // }
  }

  render() {
    const elClass = this.state.scrollFromTop > 300 ? 'arrow arrow--dissapear' : 'arrow'
    return (
      <div className={elClass}>
        <ArrowDown />
      </div>
    )
  }
}

export default ScrollDownIcon
