import React from 'react'
import { ReactComponent as PMLogo } from '../svg/pm-logo.svg'
import { ReactComponent as IndigoLogo } from '../svg/indigo.svg'
import { ReactComponent as XIcon } from '../svg/x.svg'
import Fade from 'react-reveal/Fade'

function Merch() {
  // <a href='https://theindigostuff.com/poshlaya-molly' className='merch__pm' target="_blank" rel="noopener noreferrer">
  //   <PMLogo title='ПОШЛАЯ МОЛЛИ' />
  // </a>
  // <a href='https://theindigostuff.com/poshlaya-molly' className='merch__x' target="_blank" rel="noopener noreferrer">
  //   <XIcon title='X' />
  // </a>
  // <a href='https://theindigostuff.com/poshlaya-molly' className='merch__indigo' target="_blank" rel="noopener noreferrer">
  //   <IndigoLogo title='INDIGO STUFF' />
  // </a>

  return (
    <Fade top>
      <div className='merch'>
        <div className='merch__item'>
          <a href='https://theindigostuff.com/poshlaya-molly' target="_blank" rel="noopener noreferrer">
            <img src='https://firebasestorage.googleapis.com/v0/b/poshlayamolly-832ed.appspot.com/o/2023%2Fpm-black-t-front-2.png?alt=media&token=3ac39d2d-f0ca-4884-a8ed-4bbbebf84f11' width='150' alt='Футболка PM' />
          </a>
        </div>
        <div className='merch__logo'>
          <a href='https://theindigostuff.com/poshlaya-molly' target="_blank" rel="noopener noreferrer">
            <div className='merch__pm'>
              <PMLogo title='ПОШЛАЯ МОЛЛИ' />
            </div>
            <div className='merch__x'>
              <XIcon title='X' />
            </div>
            <div className='merch__indigo'>
              <IndigoLogo title='INDIGO STUFF' />
            </div>
          </a>
        </div>
      </div>
    </Fade>
  )
}

export default Merch
