import React from 'react'
// import { render } from 'react-dom'
import { createRoot } from 'react-dom/client';
import './config'
import App from './App'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement);

// render(<App />, rootElement)
root.render(<App tab="home" />);

function unregister() {
  if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (let registration of registrations) {
        console.log('unregister')
        registration.unregister()
      }
    })
  }
}

unregister();
